import './scss/main.scss'

import $ from 'jquery';

import 'bootstrap';

import 'lightbox2';

$(".button-menu").click(function(){
	$(".menu-mobile").addClass("active");
});

$(".button-menu-close").click(function(){
	$(".menu-mobile").removeClass("active");
});

$(".menu-mobile li a").click(function(){
	$(".menu-mobile").removeClass("active");
});

$(document).on('click', 'a.row-scroll', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 1000);
});